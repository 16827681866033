import { defineMessages } from "react-intl";

export const scope = "errorPage";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "OOPS",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Un problème est survenu sur cette page,",
  },
  action: {
    id: `${scope}.action`,
    defaultMessage: "Veuillez réessayer ultérieurement.",
  },
  obsoletePageDescription: {
    id: `${scope}.obsoletePageDescription`,
    defaultMessage: "Il semble que votre version de l'application soit obsolète.",
  },
  obsoletePageAction: {
    id: `${scope}.obsoletePageAction`,
    defaultMessage:
      "Pour accéder à la dernière version et profiter de toutes les fonctionnalités, veuillez actualiser la page.",
  },
});
